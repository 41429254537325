import React, { useEffect, useRef, useState } from "react";
import Home from "../home/Home";
import QRcode from "../qrcode/QRcode";
import Login from "../login/Login";
import { COMMUNICATION_TYPE, CONTENT_TYPE, EVENT_TYPE, MESSAGE } from "../../constants/Constants";
import styles from "./ParentCompo.module.scss";
import { makeMobileUrl } from "../../utils/Utils";
import { PropsPassContext } from "../../context/UserDetails";

const ParentCompo = () => {
  const [contentType, setContentType] = useState(CONTENT_TYPE.LANDING);
  const [mobileUrl, setMobileUrl] = useState(window.location.origin);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [email, setEmail] = useState("");
  const storedOrigin = useRef("*");
  const [shopDomain,setShopDomain] = useState("");

  const handleMessage = (event, connectionType = COMMUNICATION_TYPE.RECEIVE, message = MESSAGE.CLOSE) => {
    let type, data, origin;
    if (event && event.data) {
      ({ type, data } = event.data);
      origin = event.origin;
      storedOrigin.current = event.origin;
    } else {
      type = event;
      data = message;
      origin = storedOrigin.current;
    }
    if (typeof type !== "string" && (type !== EVENT_TYPE.INFO || type !== EVENT_TYPE.REQUEST || type !== EVENT_TYPE.DETAILS)) {
      console.error("Invalid message format", type);
      return;
    }

    if (connectionType === COMMUNICATION_TYPE.RECEIVE) {
      if (type === EVENT_TYPE.INFO) {
        const url = makeMobileUrl(data);
        setMobileUrl(url);
        setShopDomain(data.shopDomain);
        setRedirectUrl(data.shopify_redirect_url);
        setEmail(data.email);
      }
    } else {
      window.parent.postMessage({ type, data }, origin);
    }
  };
  useEffect(() => {
    const messageHandler = (event) => handleMessage(event);
    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);
  
  return (
    <div className={`${styles.swanParent}`}>
      <div className={`${styles.swanParent__wrapper}`}>
        <PropsPassContext.Provider value={{ setContentType, mobileUrl, redirectUrl, handleMessage, email, setMobileUrl,shopDomain }}>
          {(() => {
            switch (contentType) {
              case CONTENT_TYPE.LANDING:
                return <Home />;
              case CONTENT_TYPE.OR_CODE:
                return <QRcode />;
              case CONTENT_TYPE.LOGIN:
                return <Login />;
              default:
                return <></>;
            }
          })()}
        </PropsPassContext.Provider>
      </div>
    </div>
  );
};

export default ParentCompo;
